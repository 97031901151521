<template>
  <nav-menu
    ref="menu"
    name="For business"
    title="Tools and discounts for businesses"
    subtitle="Employee appreciation and outreach to customers / donors"
    action="Business features"
    :icon="mdiDomainPlus"
    :to="{ name: 'business' }"
    v-bind="$attrs" v-on="$listeners"
  >

    <org-sign-up-dialog
      v-if="showSignUp"
      :value="true"
      @cancel="showSignUp = false"
      @complete="$router.push({ name: 'account-org' })"
    />

    <div class="root-grid">
      <div>
        <v-list class="transparent" dense>

          <v-list-item v-if="global.profile?.org_admin" class="li-pop ec-dark-blue--text" exact :to="{ name: 'account-org' }">
            <v-icon small v-html="mdiDomain" />
            <div>
              <div class="font-weight-bold">Manage organization</div>
              <!-- <div class="caption" style="max-width: 100%;">{{global.profile}}</div> -->
            </div>
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>

          <div v-else-if="global.profile?.org" class="pt-3 px-3 caption">Organization tools</div>

          <v-list-item v-else class="li-pop font-weight-bold ec-dark-blue--text" :to="{ name: 'account-org' }">
            <v-icon small v-html="mdiDomainPlus" />
            <div>Create an organization account…</div>
            <v-chip class="overline short" style="cursor: pointer;" color="green" dark label small>free</v-chip>
          </v-list-item>
        </v-list>

        <v-list class="transparent" dense>
          <v-list-item
            v-for="[ route, name, icon, filter ] in ACTIONS" :key="name"
            v-if="filter(global.profile)"
            class="li-pop ec-dark-blue--text"
            exact
            :to="route"
          >
            <v-icon small v-html="icon" />
            <div v-text="name" />
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>
      </div>
      <div>
        <v-list class="transparent" dense>
          <v-list-item
            class="li-pop font-weight-bold ec-dark-blue--text mb-4"
            :to="{ name: 'start-business' }"
          >
            <v-icon small v-html="mdiInformation" />
            <div>Learn more about our business platform</div>
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
          <v-list-item
            v-for="[ route, name, icon ] in LEARN_MORE" :key="name"
            class="li-pop ec-dark-blue--text"
            exact
            :to="route"
          >
            <v-icon small v-html="icon" />
            <div v-text="name" />
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>

        <router-link
          class="mx-auto my-6 d-block relative hover-pop-group"
          style="aspect-ratio: 20 / 17; max-width: min(400px, calc(100% - 24px));"
          :to="{ name: 'start-business' }"
        >
          <img class="rounded elevation-20 absolute" style="top: 0; left: 10%; width: 70%;" loading="lazy" type="image/webp" src="https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/lg.webp" srcset="https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/sm.webp 320w,https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/md.webp 640w,https://cdn.ellacard.com/media/2de601ae-067d-491c-9750-16a518a3e133/lg.webp 1280w" alt="Business tools / users">
          <img class="rounded elevation-20 absolute" style="right: 12px; bottom: 12px; width: 70%;" loading="lazy" type="image/webp" src="https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/lg.webp" srcset="https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/sm.webp 320w,https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/md.webp 640w,https://cdn.ellacard.com/media/5a52ae1d-b5e9-4b51-856b-a4c93a527c1c/lg.webp 1280w" alt="Business tools / user activity">
          <img class="rounded elevation-20 absolute" style="left: 12px; bottom: 10%; width: 60%;" loading="lazy" type="image/webp" src="https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/lg.webp" srcset="https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/sm.webp 320w,https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/md.webp 640w,https://cdn.ellacard.com/media/01508846-a32d-4878-8584-b3831eb8e677/lg.webp 1280w" alt="Import contacts">
        </router-link>
      </div>
    </div>

  </nav-menu>
</template>


<style scoped>
.root-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.root-grid > *:first-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

@media (min-width: 600px) {
  .header .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .header .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 960px) {
  .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

.li-pop:hover > .v-image:nth-child(1) {
  transform: scale(1.2);
}

.hover-pop {
  cursor: pointer;
  transition: transform .12s, filter .12s;
}

.hover-pop:hover {
  transform: scale(1.03);
  filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0 1px 5px rgba(0, 0, 0, 0.12));
}
</style>


<script>
import NavMenu from '@/components/NavMenu.vue';
import OrgSignUpDialog from '@/components/OrgSignUpDialog.vue';
import iconPathCalendarRecurring from 'raw-loader!@/assets/icon-path-calendar-recurring.txt';
import iconPathCardLock from 'raw-loader!@/assets/icon-path-card-lock.txt';
import { mdiChevronRight, mdiDomain, mdiDomainPlus, mdiCalendarStart, mdiEmailMultiple, mdiAccountGroup, mdiAccountDetails, mdiCards, mdiPalette, mdiXml, mdiInformation, mdiCurrencyUsd, mdiFrequentlyAskedQuestions, mdiApi } from '@mdi/js';


const filterAny = p => true;
const filterAdminAndNonOrgMembers = p => !p?.org || p.org_admin;
const filterOrgMembers = p => p?.org && !p.org_admin;

const ACTIONS = [
  [ { name: 'account-org' }, 'Organization settings', mdiDomain, filterOrgMembers ],
  [ { name: 'automation' }, 'Schedule automated cards', iconPathCalendarRecurring, filterAny ],
  [ { name: 'batch-order' }, 'Place a bulk order', mdiCalendarStart, filterAny ],
  [ { name: 'bulk-email' }, 'Send a mass-email', mdiEmailMultiple, filterAny ],
  [ { name: 'account-org-users' }, 'Manage shared users', mdiAccountGroup, filterAdminAndNonOrgMembers ],
  [ { name: 'account-org-user-activity' }, 'User activity metrics', mdiAccountDetails, filterAdminAndNonOrgMembers ],
  [ { name: 'account-org-orders' }, 'Manage orders', mdiCards, filterAdminAndNonOrgMembers ],
  [ { name: 'account-org-overview' }, 'Manage private card library', iconPathCardLock, filterAdminAndNonOrgMembers ],
  [ { name: 'catalog-category', params: { category: 'private:org' } }, 'Private card library', mdiCards, filterOrgMembers ],
  [ { name: 'account-org-branding' }, 'Set up custom branding', mdiPalette, filterAdminAndNonOrgMembers ],
  [ { name: 'account-org-embedding' }, 'Embed Ellacard on your site', mdiXml, filterAny ]
];

const LEARN_MORE = [
  [ { name: 'start-recognition' }, 'Employee recognition', mdiAccountGroup ],
  [ { name: 'start-automation' }, 'Automation', mdiCalendarStart ],
  [ { name: 'start-bulk-email' }, 'Bulk email', mdiEmailMultiple ],
  [ { name: 'start-embedding-api' }, 'Embedding API', mdiXml ],
  [ { name: 'pricing' }, 'Pricing', mdiCurrencyUsd ],
  [ { name: 'faq' }, 'FAQ', mdiFrequentlyAskedQuestions ],
  // [ { name: 'api-docs' }, 'API documentation', mdiApi ]
];

export default {
  name: 'nav-menu-business',

  components: {
    NavMenu,
    OrgSignUpDialog
  },

  data() {
    return {
      showSignUp: false
    };
  },

  created() {
    Object.assign(this, { ACTIONS, LEARN_MORE, mdiChevronRight, mdiDomain, mdiDomainPlus, mdiInformation });
  }
}
</script>
