<template>
  <nav-menu
    name="Group gift cards"
    title="Add gift cards"
    subtitle="Your whole group can add to one gift card"
    action="View all gift cards"
    :icon="mdiWalletGiftcard"
    :to="{ name: 'catalog-gift-cards' }"
    v-bind="$attrs" v-on="$listeners"
  >

    <v-list class="transparent" dense>
      <v-list-item class="li-pop font-weight-bold ec-dark-blue--text" :to="{ name: 'catalog-gift-cards' }">
        <v-icon small v-html="mdiWalletGiftcard" />
        <div>All gift cards</div>
        <v-icon small v-html="mdiChevronRight" />
      </v-list-item>
    </v-list>

    <v-divider />

    <div class="root-grid">

      <div>
        <div class="caption pt-3 px-3">Popular gift cards</div>
        <v-list class="transparent" dense>
          <v-list-item
            v-for="[ name, providerId, img ] in MERCHANTS" :key="name"
            class="li-pop ec-dark-blue--text"
            :to="{ name: 'catalog-gift-cards', params: { providerId } }"
          >
            <!-- <v-icon v-bind:style="{ color: c.icon_color }" small v-html="c.icon" /> -->
            <v-img class="v-card v-sheet ml-n2" width="48" max-width="48" :aspect-ratio="300 / 190" :src="img" />
            <div v-text="name" />
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>
      </div>

      <div>
        <v-list class="transparent" dense>
          <v-list-item
            v-for="[ name, icon, route ] in FEATURES" :key="name"
            class="li-pop font-weight-bold ec-dark-blue--text"
            :to="route"
          >
            <v-icon small v-html="icon" />
            <div v-text="name" />
            <v-icon small v-html="mdiChevronRight" />
          </v-list-item>
        </v-list>

        <div v-if="false" class="pa-3">
          <div class="caption">Search all gift cards</div>
          <v-text-field
            class="mt-2 white"
            label="Search" outlined dense rounded hide-details multiple single-line clearable readonly
            :append-icon="mdiMagnify"
            @click="$router.push({ name: 'catalog-gift-cards' })"
          />
        </div>
      </div>

    </div>

    <!--
    <v-card class="ec-dark-blue--text nav-menu-header pa-3" flat tile Xto="to">
      <div class="text-h6 text-sm-h5" style="line-height: 1.5;">Add gift cards</div>
      <div class="mt-1 mb-4">Your whole group can contribute to one gift card</div>
      <v-btn class="px-2" dark color="primary">
        <v-icon v-html="icon" />
        <span class="mx-2" style="text-transform: none;">Learn more</span>
        <v-icon v-html="mdiChevronRight" />
      </v-btn>
    </v-card>
    <v-divider />
    <v-row class="ma-0">
      <v-col cols="6" sm="5">
        <div class="caption">Popular gift cards</div>

        <div v-for="[ name, providerId, img ] in MERCHANTS" class="mx-n2">
          <v-btn text color="primary" :to="{ name: 'catalog-gift-cards', params: { providerId } }">
            <v-img class="mr-2" width="32" :aspect-ratio="300 / 190" :src="img" />{{name}}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="7">
        <div class="caption mb-1">Send gift cards in bulk</div>
        <v-btn class="ma-n2" text color="primary" :to="{ name: 'batch-order' }">
          <v-icon small class="mr-1" v-html="mdiCheckboxMultipleBlank" />Create bulk order
        </v-btn>
        <v-row class="mx-n1 mt-2 mb-1">
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="https://cdn.ellacard.com/nav/giftcard_batch_1.png" />
            </v-card>
          </v-col>
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="https://cdn.ellacard.com/nav/giftcard_batch_2.png" />
            </v-card>
          </v-col>
          <v-col class="pa-1" cols="4">
            <v-card>
              <v-img :aspect-ratio="5 / 7" src="https://cdn.ellacard.com/nav/giftcard_batch_3.png" />
            </v-card>
          </v-col>
        </v-row>

        <div class="caption mt-6 mb-1">Send internationally</div>
        <v-btn class="ma-n2" text color="primary" :to="{ name: 'international-gift-cards' }">
          <v-icon small color="primary" class="mr-1" v-html="mdiEarth" />Supported countries / merchants
        </v-btn>
      </v-col>
    </v-row>
    -->

  </nav-menu>
</template>


<style scoped>
.root-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.root-grid > *:first-child {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

@media (min-width: 600px) {
  .header .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .header .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 960px) {
  .root-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .root-grid > *:first-child {
    border-bottom: none;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
}

.li-pop:hover > .v-image:nth-child(1) {
  transform: scale(1.2);
}
</style>


<script>
import NavMenu from '@/components/NavMenu.vue';
import iconPathCalendarRecurring from 'raw-loader!@/assets/icon-path-calendar-recurring.txt';
import { mdiCheckboxMultipleBlank, mdiEmailMultiple, mdiCalendarStart, mdiEarth, mdiWalletGiftcard, mdiMagnify, mdiChevronRight, mdiInformation } from '@mdi/js';

const MERCHANTS = [
  [ 'Amazon', 'RU:AMZ-US', 'https://gift.runa.io/static/product_assets/AMZ/AMZ-card.png' ],
  [ 'Doordash', 'RU:DDASH-US', 'https://gift.runa.io/static/product_assets/DDASH-US/DDASH-US-card.png' ],
  // [ 'Apple', 'TR:DC82VBYLI4CC', 'https://giftrocket-s3.imgix.net/Brands/US/iTunes/Digital/B2B/iTunes_B2B.png' ],
  // [ 'Google', 'RU:PLAY-US', 'https://gift.runa.io/static/product_assets/PLAY-US/PLAY-US-card.png' ],
  [ 'Home Depot', 'RU:DEPOT-US', 'https://giftrocket-s3.imgix.net/Brands/US/The+Home+Depot/Digital/The+Home+Depot.png' ],
  // [ `Lowe's`, 'RU:LOWES-US', 'https://gift.runa.io/static/product_assets/LOWES-US/LOWES-US-card.png' ],
  [ 'REI', 'RU:REI-US', 'https://gift.runa.io/static/product_assets/REI-US/REI-US-card.png' ],
  [ 'Sephora', 'RU:SEPHRA-US', 'https://gift.runa.io/static/product_assets/SEPHRA-US/SEPHRA-US-card.png' ],
  [ 'Starbucks', 'RU:SBUX-US', 'https://giftrocket-s3.imgix.net/Brands/US/Starbucks/Digital/starbucks_image_for_certificate.png' ],
  [ 'Target', 'RU:TARGT-US', 'https://giftrocket-s3.imgix.net/Brands/US/Target/Digital/Target.png' ],
  [ 'Uber Eats', 'RU:UEATS-US', 'https://gift.runa.io/static/product_assets/UEATS-US/UEATS-US-card.png' ],
  [ 'Ulta Beauty', 'RU:ULTAB-US', 'https://gift.runa.io/static/product_assets/ULTAB-US/ULTAB-US-card.png' ],
  [ 'Visa', 'RU:VISA-US', 'https://cdn.ellacard.com/giftcard_tr_visa.png' ],
  [ 'Walmart', 'RU:WMART-US', 'https://gift.runa.io/static/product_assets/WMART-US/WMART-US-card.png' ]
];

const FEATURES = [
  [ 'Learn more about gift cards', mdiInformation, { name: 'start-gift-cards' } ],
  [ 'Schedule automated cards', iconPathCalendarRecurring, { name: 'automation' } ],
  [ 'Place a bulk order', mdiCalendarStart, { name: 'batch-order' } ],
  [ 'Send internationally', mdiEarth, { name: 'international-gift-cards' } ]
];

export default {
  name: 'nav-menu-group-gift-cards',

  components: {
    NavMenu
  },

  created() {
    Object.assign(this, { FEATURES, MERCHANTS, mdiCheckboxMultipleBlank, mdiEarth, mdiWalletGiftcard, mdiMagnify, mdiChevronRight });
  }
}
</script>
