function getTimezoneName(d, mode, timeZone, locale) {
  // 'mode' is one of 'short', 'long', 'shortGeneric', or 'longGeneric'
  // https://stackoverflow.com/a/56490104/8439453

  // This works, but returns the date time as something like 'America/Los_Angeles' which is unambiguous, but less
  // human-readable:
  // Intl.DateTimeFormat().resolvedOptions().timeZone;

  d = d ? new Date(d) : new Date();
  const short = d.toLocaleDateString(locale, { timeZone });
  const full = d.toLocaleDateString(locale, { timeZone, timeZoneName: mode });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  } else {
    return full;
  }
}

function getUTCOffset(timeZone, onDate) {
  const date = new Date(onDate);
  date.setSeconds(0, 0);
  const iso = date.toLocaleString('sv', { timeZone });
  const [ y, m, d, hh, mm ] = /(\d+)-(\d+)-(\d+) (\d+):(\d+)/.exec(iso).slice(1).map(Number);
  const utc = Date.UTC(y, m - 1, d, hh, mm);
  const offset = Math.floor((utc - date.getTime()) / (1000 * 60));
  const offsetH = Math.floor(offset / 60);
  const offsetM = Math.abs(offset) % 60;
  return ((offsetH < 0) ? '-' : '+') + String(Math.abs(offsetH)).padStart(2, '0') + ':' + String(offsetM).padStart(2, '0');
}

function getISO(date, time, tz) {
  if (!date || !time || !tz)
    return null;
  const root = date + 'T' + time;
  const parsed = new Date(root);
  if (isNaN(parsed) || (date != format(parsed, 'iso', tz).slice(0, 10)))
    return null;
  const offset = getUTCOffset(tz, parsed);
  return root + offset;
}

function getDateStr(d, timeZone) {
  // Only include the year if the date is at least 6 months in the future
  const year = d.getTime() - (new Date()).getTime() > 180 * 24 * 3600 * 1000 ? 'numeric' : undefined;
  return d.toLocaleDateString(undefined, { weekday: 'long', year, month: 'long', day: 'numeric', timeZone });
}

function getTimeRangeStr(fromD, toD, timeZone) {
  // 'toD' is optional
  let output = fromD.toLocaleTimeString(undefined, { timeStyle: 'short', timeZone });
  if (toD) {
    const to = toD.toLocaleTimeString(undefined, { timeStyle: 'short', timeZone });
    const suffix = [ ' AM', ' PM' ].find(x => output.endsWith(x) && to.endsWith(x));
    if (suffix)
      output = output.slice(0, -suffix.length)
    output += ' – ' + to;
  }
  return output;
}


const EN_ORDINAL_SUFFIXES = [ 'st', 'nd', 'rd' ];
const TH_EXCEPTIONS = new Set([ 11, 12, 13 ]);

function getOrdinalSuffixEN(i) {
  return EN_ORDINAL_SUFFIXES[TH_EXCEPTIONS.has(i) ? -1 : i % 10 - 1] || 'th';
}

const FMT_REL_UNITS = [
  [ 'year', 31536000 ],
  [ 'month', 2628000 ],
  [ 'day', 86400 ],
  [ 'hour', 3600 ],
  [ 'minute', 60 ],
  [ 'second', 1 ]
];

function formatRelative(d, locale) {
  const diff = ((new Date(d)).getTime() - (new Date()).getTime()) / 1000;
  for (const [ unit, scale ] of FMT_REL_UNITS) {
    if (Math.abs(diff) >= scale) {
      const t = Math.round(diff / scale) || 0;
      try {
        const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
        return rtf.format(Math.round(diff / scale) || 0, unit);
      } catch (e) {
        return t + ' ' + unit + (t == 1 ? '' : 's');
      }
    }
  }
}

function format(d, mode, timeZone, locale) {
  // 'd' should be a Date object itself, or a complete ISO datetime string (with timezone information)
  // TODO: I'd like to add ordinal suffixes to long dates (at least for us-EN)
  // if ((locale || navigator.language) == 'en-US')
  //   // We can only add the ordinal suffix in locales where we are sure the day is the last element
  //   return s + getOrdinalSuffixEN(parseInt(s.match(/ (\d+), \d{4}$/)[1]));
  if (!d)
    return '';
  d = new Date(d);
  switch (mode) {
    case 'weekday':
      return d.toLocaleString(locale, { timeZone, weekday: 'long' });
    case 'short-date':
      return d.toLocaleString(locale, { timeZone, dateStyle: 'short' });
    case 'long-date':
      return d.toLocaleString(locale, { timeZone, dateStyle: 'long' });
    case 'long-month-date-ordinal':
      return d.toLocaleString(locale, { timeZone, month: 'long' }) + ' ' + d.getDate() + getOrdinalSuffixEN(d.getDate());
    case 'time':
      return d.toLocaleString(locale, { timeZone, timeStyle: 'short' });
    case 'short':
    case 'long':
      return d.toLocaleString(locale, { timeZone, dateStyle: mode, timeStyle: 'short' }) + ' ' + getTimezoneName(d, 'short', timeZone, locale);
    case 'relative':
      try {
        return formatRelative(d, locale);
      } catch (e) {
        return format(d, '', timeZone, locale)
      }
    case 'iso':
      // Note: you used to simply use 'toISOString' here, which always returned things in UTC (with a 'Z') regardless
      // of the input 'timeZone'
      return d.toLocaleDateString('sv', { timeZone }) + 'T' + d.toLocaleTimeString('sv', { timeZone, timeStyle: 'short' }) + getUTCOffset(timeZone, d);
    case 'iso-date':
      return d.toLocaleDateString('sv', { timeZone });
    case 'iso-time':
      return d.toLocaleTimeString('sv', { timeZone, timeStyle: 'short' });
    default:
      try {
        return d.toLocaleString(locale, { timeZone, ...mode });
      } catch (e) {
      }
  }
  throw new Error('Unsupported mode: ' + mode);
}

export { getUTCOffset, getTimezoneName, getDateStr, getTimeRangeStr, format, getOrdinalSuffixEN, getISO };
