<template>
  <div :key="blob.src" class="absolute-fill overflow-hidden" v-bind:style="crop">

    <!-- This is used as an indicator for both loading and image templates -->
    <div
      v-if="loading || (!isPreview && blob.template && !blob.user_id && isEditable && !isSelected)"
      class="absolute-fill template-editable"
    />

    <div v-if="/^{[^{}]+}$/.test(blob.img.src)" class="placeholder-name pa-3 d-flex justify-center align-center">
      <v-icon class="mr-1" v-html="mdiImageMultiple" />
      {{blob.img.src}}
    </div>

    <video
      v-if="!static && blob.img.is_mp4"
      class="user-select-none"
      playsinline autoplay muted loop
      :poster="blob.img.src_static"
      :src="blob.img.src"
      @loadedmetadata="end"
      @error="end"
    />

    <picture v-else draggable="false" class="user-select-none">
      <img
        v-if="static && blob.img.src_static"
        draggable="false"
        :src="blob.img.src_static"
        @load="end" @error="end" @abort="end"
      >
      <template v-else>
        <!-- Animated WEBP images don't play back properly in Safari -->
        <source
          v-if="(SUPPORTS_ANIMATED_WEBP || !blob.img.src_static) && blob.img.try_webp"
          type="image/webp"
          :srcset="blob.img.src + '.webp'"
        >
        <img :src="proxy(blob.img.src)" draggable="false" @load="end" @abort="end" @error="onError">
      </template>
    </picture>
  </div>
</template>

<style scoped>
img, video, .placeholder-name {
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
}
.placeholder-name {
  font-family: monospace;
  background-color: #EEE;
}
.template-editable {
  box-sizing: border-box;
  border: 1px dashed rgb(25, 118, 210);
  transition: background-color .2s;
}
.template-editable:hover {
  background-color: rgb(25, 118, 210, .08);
}
</style>

<script>
// If you want to experiment with slow image loading, just prefix the 'src' URL with this:
// 'http://www.deelay.me/1000/'

import { mdiImageMultiple } from '@mdi/js';

const SUPPORTS_ANIMATED_WEBP = !/(iPad|iPhone|iPod)\s+OS\s+\d\d_.*AppleWebKit/.test(navigator.userAgent);

export default {
  name: 'image-blob',

  props: [ 'blob', 'isEditable', 'isPreview', 'isSelected', 'static' ],

  data() {
    return {
      loading: true,
      isDragging: false
    };
  },

  computed: {
    crop() {
      const styles = {};
      if (this.blob.crop) {
        for (const key of [ 'top', 'right', 'bottom', 'left' ])
          styles[key] = `-${this.blob.crop[key] || 0}%`;
        if (this.blob.crop.r)
          styles.transform = `rotate(${this.blob.crop.r}deg)`;
      }
      return styles;
    }
  },

  created() {
    Object.assign(this, { SUPPORTS_ANIMATED_WEBP, mdiImageMultiple });
  },

  methods: {
    proxy(url) {
      if (/^{[^{}]+}$/.test(url))
        return '/template-placeholder.png';
      return this.global.proxyTenor ? url.replace('media.tenor.com', 'gif.ellacard.com') : url;
    },

    onError() {
      if (this.blob.img.src.includes('media.tenor.com'))
        this.global.proxyTenor = true;
      this.end();
    },

    end() {
      this.loading = false;
      this.$emit('loaded');
    }
  }
}
</script>
