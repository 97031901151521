<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="createOrg">

    <error-dialog :status="loadAccountStatus" />
    <error-dialog :status="createOrgStatus" />

    <sign-in-dialog v-model="showSignIn">
      <template v-slot:subtitle>Sign in to verify your email address</template>
    </sign-in-dialog>

    <v-text-field
      v-if="!hideEmail"
      class="mb-6"
      :value="isSignedIn ? global.profile.email : null"
      name="Email address" label="Email address" readonly
      :error-messages="accountErr"
      :rules="[ v => !!v || 'Required' ]"
      @click="showSignIn = true"
    >
      <template v-slot:append-outer>
        <v-btn
          small
          :loading="loadAccountStatus.pending"
          @click="showSignIn = true"
          v-text="isSignedIn ? 'Change…' : 'Sign in…'"
        />
      </template>
    </v-text-field>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined dense name="First name" label="First name"
          :disabled="isDisabled"
          :rules="[ v => !!v || 'Required' ]"
          v-model="params.first_name"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined dense name="Last name" label="Last name"
          :disabled="isDisabled"
          :rules="[ v => !!v || 'Required' ]"
          v-model="params.last_name"
        />
      </v-col>
    </v-row>
    <v-text-field
      outlined dense name="Organization name" label="Organization name"
      :disabled="isDisabled"
      :rules="[ v => !!v || 'Required' ]"
      v-model="params.org_name"
    />
    <v-textarea
      hide-details outlined label="Please describe your use case" auto-grow
      :disabled="isDisabled"
      v-model="params.notes"
    />
    <div class="mt-1 text--label-active">Ellacard has powerful tools to support your use case – we'll point you in the right direction to help you get started</div>

    <div v-if="!hideBtns" class="mt-6 d-flex">
      <v-spacer />
      <v-btn v-if="!hideCancel" class="mr-4" @click="$emit('cancel')">Cancel</v-btn>
      <v-btn
        color="primary"
        :disabled="isDisabled"
        :loading="createOrgStatus.pending"
        @click="$refs.form.validate() && createOrg()"
      >
        Submit
      </v-btn>
    </div>
  </v-form>
</template>


<script>
import SignInDialog from '@/components/SignInDialog.vue';
import { OPError } from '@/utils/op-dev-fetch';


export default {
  name: 'org-sign-up-form',

  components: {
    SignInDialog
  },

  props: {
    hideCancel: Boolean,
    hideEmail: Boolean,
    hideBtns: Boolean
  },

  data() {
    return {
      account: null,
      showSignIn: false,
      params: {
        first_name: null,
        last_name: null,
        org_name: null,
        notes: null
      },
      isValid: false,
      loadAccountStatus: this.$track('loadAccount'),
      createOrgStatus: this.$track('createOrg')
    };
  },

  computed: {
    accountErr() {
      if (!this.isSignedIn)
        return 'Sign in to verify your email address';
      if (this.global.profile.org)
        return 'This account already belongs to an organization';
      if ((this.account?.subscription?.metadata.code == 'SINGLE-USER') && this.account?.subscription.next_at)
        // Note that this also catches the case where a user has signed up for but cancelled their single-user
        // subscription
        return 'Access to org tools is not allowed with a SINGLE-USER subscription';
      return null;
    },

    isDisabled() {
      return !!this.accountErr || this.loadAccountStatus.pending;
    }
  },

  methods: {
    getOrgParams() {
      // Warning: this is called externally from components like the PaymentDialog
      return this.$refs.form.validate() ? this.params : null;
    },

    async loadAccount() {
      this.account = await this.$call('get-account');
    },

    async createOrg() {
      if (this.isDisabled)
        return;

      try {
        await this.$call('/create-org', { ...this.params, url: location.href });
        await this.$refreshJWT();
        this.$emit('complete');
      } catch (e) {
        if (e instanceof OPError && e.message == 'EXISTING_ORG')
          throw new VisibleError('This account is already part of an Ellacard organization');
        if (e instanceof OPError && e.message == 'EXISTING_SUBSCRIPTION')
          throw new VisibleError('This account is already subscribed as an individual and cannot be part of an Ellacard organization – please contact us to upgrade your subscription: <a href="mailto:contact@ellacard.com">contact@ellacard.com</a>');
        throw e;
      }
    }
  },

  watch: {
    globalUserId: {
      immediate: true,
      handler(v) {
        this.account = null;
        v && this.loadAccount();
      }
    }
  }
}
</script>
