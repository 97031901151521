var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.blob.src,
    staticClass: "absolute-fill overflow-hidden",
    style: _vm.crop
  }, [_vm.loading || !_vm.isPreview && _vm.blob.template && !_vm.blob.user_id && _vm.isEditable && !_vm.isSelected ? _c('div', {
    staticClass: "absolute-fill template-editable"
  }) : _vm._e(), /^{[^{}]+}$/.test(_vm.blob.img.src) ? _c('div', {
    staticClass: "placeholder-name pa-3 d-flex justify-center align-center"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    domProps: {
      "innerHTML": _vm._s(_vm.mdiImageMultiple)
    }
  }), _vm._v(" " + _vm._s(_vm.blob.img.src) + " ")], 1) : _vm._e(), !_vm.static && _vm.blob.img.is_mp4 ? _c('video', {
    staticClass: "user-select-none",
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "muted": "",
      "loop": "",
      "poster": _vm.blob.img.src_static,
      "src": _vm.blob.img.src
    },
    domProps: {
      "muted": true
    },
    on: {
      "loadedmetadata": _vm.end,
      "error": _vm.end
    }
  }) : _c('picture', {
    staticClass: "user-select-none",
    attrs: {
      "draggable": "false"
    }
  }, [_vm.static && _vm.blob.img.src_static ? _c('img', {
    attrs: {
      "draggable": "false",
      "src": _vm.blob.img.src_static
    },
    on: {
      "load": _vm.end,
      "error": _vm.end,
      "abort": _vm.end
    }
  }) : [(_vm.SUPPORTS_ANIMATED_WEBP || !_vm.blob.img.src_static) && _vm.blob.img.try_webp ? _c('source', {
    attrs: {
      "type": "image/webp",
      "srcset": _vm.blob.img.src + '.webp'
    }
  }) : _vm._e(), _c('img', {
    attrs: {
      "src": _vm.proxy(_vm.blob.img.src),
      "draggable": "false"
    },
    on: {
      "load": _vm.end,
      "abort": _vm.end,
      "error": _vm.onError
    }
  })]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }