import { getFixedInputs } from '@/utils/event-rsvp-form.js';
import { LOCALE, TZ } from '@/utils/intl.js';
import { format } from '@/utils/time.js';

function fixTimeString(hmm) {
  // I ran into an issue at one point where I saw incomplete time strings being passed to the backend (they were
  // missing seconds) - I think it was coming from legacy orders (not sure where), but this should fix it anyway
  const [ h, mm ] = hmm.split(':');
  return h.padStart(2, '0') + ':' + mm.padStart(2, '0') + ':00';
}

const LEGACY_FORM_FIXED_FIELDS = {
  'Are you able to attend?': {
    public: true,
    lock: [ 'move', 'title', 'subtitle', 'required', 'delete', 'choices' ]
  },
  'How many people are in your party?': {
    public: true,
    lock: [ 'move', 'title', 'subtitle', 'required', 'delete', 'range' ]
  }
};

export default function(order) {
  // TODO: this is a kludge - should be updated in the DB
  if (order.lock == 'SENT')
    order.lock = 'MANUAL';

  if (!order.locale)
    order.locale = (order.state == 'PENDING') ? LOCALE : 'en-US';

  if (order.itemized?.org_id)
    order.org_id = order.itemized.org_id;

  if (!order.skin)
    order.skin = {};

  if (!order.hasOwnProperty('tz'))
    order.tz = TZ;

  if (!order.hasOwnProperty('locale'))
    order.locale = LOCALE;

  const event = order.event;
  if (event) {
    // Legacy forms attached their information to a blob
    const legacy = _.flatten(order.pages.map(x => x.blobs)).find(x => x.btn?.form)?.btn.form;

    if (!event.form) {
      event.form = {};
      const existing = _.flatten(order.pages.map(x => x.blobs)).find(x => x.btn?.form);
      if (existing) {
        event.allowed = existing.btn.form.allowed;
        event.form.inputs = existing.btn.form.inputs;
        for (const input of event.form.inputs)
          if (input.title == 'Are you able to attend?')
            input.required = true;
      }
    }
    if (!event.tz)
      event.tz = order.tz;
    if (!event.hasOwnProperty('address'))
      event.address = null;
    if (!event.hasOwnProperty('date_start'))
      event.date_start = event.from ? format(event.from, 'iso-date', event.tz) : null;
    if (!event.hasOwnProperty('time_start'))
      event.time_start = event.from ? format(event.from, 'iso-time', event.tz) : null;
    if (event.time_start && (event.time_start.length < 8))
      event.time_start = fixTimeString(event.time_start);
    if (!event.hasOwnProperty('time_end'))
      event.time_end = event.to ? format(event.to, 'iso-time', event.tz) : null;
    if (event.time_end && (event.time_end.length < 8))
      event.time_end = fixTimeString(event.time_end);

    if (!event.allowed)
      event.allowed = legacy?.allowed || 'UNINVITED';
    if (![ 'INVITED', 'UNINVITED', 'NONE' ].includes(event.allowed))
      event.allowed = 'UNINVITED';
    if (!event.hasOwnProperty('rsvp_notify'))
      event.rsvp_notify = false;
    if (!event.hasOwnProperty('public_guest_list'))
      event.public_guest_list = false;
    if (!event.hasOwnProperty('public_guest_list_mode'))
      event.public_guest_list_mode = event.public_guest_list_all ? 'ALL' : 'ALL_RSVP_ATTENDING';
    if (!event.form.hasOwnProperty('n_rsvp'))
      event.form.n_rsvp = 100;
    if (!event.form.hasOwnProperty('separate_counts'))
      event.form.separate_counts = false;
    if (!event.form.hasOwnProperty('request_names'))
      event.form.request_names = false;

    if (!event.form.inputs) {
      if (legacy) {
        if (order.state == 'PENDING') {
          event.form.inputs = [ ...getFixedInputs(event.form), ...legacy.inputs.filter(x => !LEGACY_FORM_FIXED_FIELDS[x.title]) ];
        } else {
          event.form.inputs = legacy.inputs;
          for (const input of event.form.inputs)
            Object.assign(input, LEGACY_FORM_FIXED_FIELDS[input.title] || {});
        }
      } else {
        event.form.inputs = [ ...getFixedInputs(event.form), { type: 'TEXT', title: 'Note to host', public: false } ];
      }
    }

    // This shouldn't happen, but I want to make sure we don't get into a state with duplicate inputs
    const titles = new Set();
    event.form.inputs = event.form.inputs.filter(x => !titles.has(x.title) && titles.add(x.title));

    for (const input of event.form.inputs) {
      if ((input.type == 'RADIO') && (input.title == 'Are you able to attend?'))
        input.public = true;
      if ((input.type == 'RADIO') && !input.hasOwnProperty('choices'))
        input.choices = [];
      if ((input.type == 'INT') && !input.hasOwnProperty('min'))
        input.min = 0;
      if ((input.type == 'INT') && !input.hasOwnProperty('max'))
        input.max = 99;
    }
  }

  const info = order.info;
  if (!info.recipients)
    info.recipients = [];
  if (!info.contributors)
    info.contributors = [];
  if (!info.recipient_groups)
    info.recipient_groups = info.recipients.map(x => ({ to: x.to, targets: [ x.email || x.phone ] }));
  if (!info.contributor_groups)
    info.contributor_groups = info.contributors.map(x => ({ to: x.to, targets: [ x.email || x.phone ] }));
  if (order.type == 'INVITATION') {
    const n_rsvp = order.event?.form?.n_rsvp || 100;
    for (const group of info.recipient_groups) {
      if (!group.hasOwnProperty('n_rsvp'))
        group.n_rsvp = n_rsvp;
    }
  }
  if (!info.hasOwnProperty('send_at_contributors_delta_s'))
    info.send_at_contributors_delta_s = null;
  if (!info.hasOwnProperty('send_at_contributors'))
    info.send_at_contributors = (new Date()).toISOString();

  if (!order.hasOwnProperty('multiple_recipients'))
    order.multiple_recipients = (order.type == 'INVITATION') || (info.recipient_groups.length > 1);

  if (!info.hasOwnProperty('from'))
    info.from = '';
  if (!info.hasOwnProperty('to'))
    info.to = (!order.multiple_recipients && info.recipient_groups[0]?.to) || '';

  // There are a number of properties that are used to help us navigate through the order stepper but are user
  // purely to simplify the interface and don't directly affect anything. For legacy orders, we need to make sure
  // that all these properties exist.
  if (!order.method_contributors) {
    order.method_contributors = [ 'link' ];
    if (info.contributors?.length || info.contributor_groups.length)
      order.method_contributors.push('send');
  }
  if (!order.method_recipients) {
    order.method_recipients = [ 'link' ];
    if (info.recipients?.length || info.recipient_groups.length)
      order.method_recipients.push('send');
  }
  if (!order.dirty)
    order.dirty = [];

  if (!order.hasOwnProperty('send_recipients_choice')) {
    if (info.send_at) {
      if (order.completed_at)
        order.send_recipients_choice = (info.send_at < order.completed_at) ? 'NOW' : 'SCHEDULED';
      else
        order.send_recipients_choice = (new Date(info.send_at) < new Date()) ? 'NOW' : 'SCHEDULED';
    } else {
      order.send_recipients_choice = null;
    }
  }

  if (!order.hasOwnProperty('lock'))
    order.lock = null;

  delete order.info.contributors;
  delete order.info.recipients;
}
