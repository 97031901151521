var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.ACTIONS, function (_ref, i) {
    var to = _ref[0],
      title = _ref[1],
      icon = _ref[2];
    return _c('v-list-item', {
      key: title,
      staticClass: "li-pop ec-dark-blue--text",
      class: {
        'mb-6': i == 2 || i == 7,
        'font-weight-bold': i < 3
      },
      attrs: {
        "to": to
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(icon)
      }
    }), _c('div', {
      domProps: {
        "innerHTML": _vm._s(title)
      }
    }), _c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "innerHTML": _vm._s(_vm.mdiChevronRight)
      }
    })], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }