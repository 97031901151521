<template>
  <dialog-scroll-lock
    scrollable
    :value="true"
    :width="width"
    :persistent="persistent"
    @input="resolve(dismiss || buttons[0])"
  >
    <v-card class="pt-4" :dark="dark" :color="color">
      <v-card-title v-if="title" class="pt-0 overflow-auto" v-html="title" />
      <v-card-text v-if="msg" class="break-word" v-html="msg" />
      <v-card-actions>
        <v-spacer/>
        <v-progress-circular v-if="loadTimeout" class="mr-3" indeterminate size="24" width="2" color="grey" />
        <v-btn
          v-for="btn in buttons" :key="btn"
          text
          :disabled="!!loadTimeout"
          :color="btn == primary ? 'primary' : null"
          @click="resolve(btn)"
        >
          {{btn}}
        </v-btn>
      </v-card-actions>
      <template v-if="finePrint">
        <v-divider />
        <v-card-text class="pt-5 break-word caption font-italic" v-html="finePrint" />
      </template>
    </v-card>
  </dialog-scroll-lock>
</template>

<script>
export default {

  props: {
    title: String,
    msg: String,
    finePrint: String,
    buttons: { type: Array, default: () => [ 'No', 'Yes' ] },
    primary: { type: String, default: 'Yes' },
    width: { type: Number, default: 300 },
    persistent: { type: Boolean, default: true },
    dismiss: String,
    loading: { type: Number, default: 0 },
    resolve: Function,
    dark: Boolean,
    color: String
  },

  data() {
    return { loadTimeout: null };
  },

  mounted () {
    document.addEventListener('keyup', this.onKeyUp);
    if (this.loading)
      this.loadTimeout = setTimeout(() => this.loadTimeout = null, this.loading);
  },

  destroyed () {
    document.removeEventListener('keyup', this.onKeyUp);
    if (this.loadTimeout)
      clearTimeout(this.loadTimeout);
  },

  methods: {
    onKeyUp(e) {
      if (!this.loadTimeout && (e.keyCode == 13) && (this.buttons.indexOf(this.primary) != -1)) {
        e.stopPropagation();
        this.resolve(this.primary);
      }
    }
  }
}
</script>
